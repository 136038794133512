import * as React from "react";
import * as styles from "./contents.module.css";

export default function Contents({blogpost}) {
    return (<div>
        <div className={styles.title}>
            <h1>Contents</h1>
            <hr/>
        </div>
        <div>
            <ul className={styles.itemsList}>
                {blogpost.content.sections.map((section, index) => (section.title && <li
                    className={styles.contents_item} key={index}
                    onClick={() => window.scrollTo({
                        top: document.getElementById(`section_${index}`).offsetTop - 100, behavior: "smooth"
                    })}>{section.title}</li>)
                )}
            </ul>
        </div>
    </div>);
}
