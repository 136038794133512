import React from "react";
import "../i18n";
import Layout from "../components/Layout/Layout";
import BlogContent from "./BlogContent";
import Seo from "../components/shared/Seo";


export default function Blogpost({pageContext}) {
    return (
        <Layout>
            <Seo title={`${pageContext.blogpost.title} | Blog`} description={pageContext.blogpost.title}
                 canonicalPath={`/blog/${pageContext.blogpost.slug}`} articleMetaData={{
                author: pageContext.blogpost.author,
                published_time: pageContext.blogpost.createdAt,
                tag: Array.of(pageContext.blogpost.tags).reduce((a, b) => `${a},${b}`)
            }}/>
            <BlogContent blogpost={pageContext.blogpost}/>
        </Layout>
    );
}
