import * as React from "react";
import * as styles from "./Body.module.css";
import {useTranslation} from "react-i18next";

export default function Body({ blogpost }) {
  const {t, i18n} = useTranslation();
  return (<div>
    <p className={styles.intro} dangerouslySetInnerHTML={{ __html: blogpost.content.intro }} />
    {blogpost.content.sections.map((section, index) => <div key={index}>
      <h1 className={styles.section_title} id={`section_${index}`}>{section.title}</h1>
      <p className={styles.section_content} dangerouslySetInnerHTML={{ __html: section.content.replace("$APP_URL", `${process.env.GATSBY_BASE_URL}/register?lang=${i18n.language}`) }} />
    </div>)}
  </div>);
}
