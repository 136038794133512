import React from "react";
import * as styles from "./BlogPostAuthor.module.css";

export default function BlogPostAuthor({ photo, author, createdAt }) {
  const formatDateInEnglish = (date) => {
    const formattedDate = new Date(date);
    return `Published on ${formattedDate.getDate()}th ${formattedDate.toLocaleString("en-us", { month: "long" })}, ${formattedDate.getFullYear()}`;
  };
  return (<div className={styles.container}>
    <img src={`/images/${photo}`} className={styles.img} />
    <div className={styles.info}>
      <h1 className={styles.name}>{author}</h1>
      <h2 className={styles.date}>{formatDateInEnglish(createdAt)}</h2>
    </div>
  </div>);
}
