import React from "react";
import * as styles from "./blogpost.module.css";
import Contents from "../components/Contents/Contents";
import Body from "../components/Body/Body";
import BlogPostAuthor from "../components/BlogPostAuthor/BlogPostAuthor";
import clock from "../../static/icons/clock.png";

export default function BlogContent({blogpost}) {
    return (
        <div className={styles.blog_container}>
            <div className={styles.header} style={{
                backgroundColor: `${blogpost.color}`
            }}>
                <div className={styles.title}>
                    <h1 className={styles.section_title}>{blogpost.section}</h1>
                    <h1 className={styles.blogpost_title}>{blogpost.title}</h1>
                </div>
                {blogpost.author && <div className={styles.info}>
                    <BlogPostAuthor author={blogpost.author} createdAt={blogpost.createdAt}
                                    photo={blogpost.author_image}/>
                    <div className={styles.reading_time}>
                        <img src={clock} alt="reading-time"/>
                        <p className={styles.time}>{blogpost.reading_time}</p>
                    </div>
                </div>}
            </div>
            <div>
            </div>
            <div className={styles.content}>
                <div className={styles.left_section}>
                    <Body blogpost={blogpost}/>
                </div>
                <div className={styles.right_section}>
                    <Contents blogpost={blogpost}/>
                </div>
            </div>
        </div>
    );
}
