// extracted by mini-css-extract-plugin
export var bg = "blogpost-module--bg--Rhl+K";
export var blog_container = "blogpost-module--blog_container---H-pp";
export var blogpost_title = "blogpost-module--blogpost_title--9QsKK";
export var content = "blogpost-module--content--dQLaX";
export var header = "blogpost-module--header--Cvjje";
export var info = "blogpost-module--info--ofvWJ";
export var left_section = "blogpost-module--left_section--A+PyO";
export var reading_time = "blogpost-module--reading_time--URYdW";
export var right_section = "blogpost-module--right_section--ARAqI";
export var section_title = "blogpost-module--section_title--JuE8A";
export var tag = "blogpost-module--tag--SXrLN";
export var tags = "blogpost-module--tags--GGJtC";
export var time = "blogpost-module--time--JnF+5";
export var title = "blogpost-module--title--4pvMG";